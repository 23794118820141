/**
 * 文件链接转文件流下载--主要针对pdf 解决谷歌浏览器a标签下载pdf直接打开的问题
 * @param url  ：文件链接
 * @param fileName  ：文件名;
 * @param type  ：文件类型;
 */
function downloadFiles(url, fileName, type) {
  let that = this
  if (process.env.NODE_ENV === 'production') {
    url = 'api/' + url
  }
  let xhr = new XMLHttpRequest()
  xhr.open('get', url, true)
  xhr.setRequestHeader('Content-Type', `application/${type}`)
  xhr.responseType = 'blob'
  /* //监听进度事件
  xhr.addEventListener(
    'progress',
    function (evt) {
      if (evt.lengthComputable) {
        let percentComplete = evt.loaded / evt.total
        per.percentage = (percentComplete * 10000) / 100
      }
    },
    false
  )*/
  xhr.onload = function () {
    if (this.status == 200) {
      //接受二进制文件流
      let blob = this.response
      downloadExportFile(blob, fileName, type)
    }
  }
  xhr.send()
}

/**
 *下载导出文件
 * @param blob  ：返回数据的blob对象或链接
 * @param tagFileName  ：下载后文件名标记
 * @param fileType  ：文件类 word(docx) excel(xlsx) ppt等
 */
function downloadExportFile(blob, tagFileName, fileType) {
  let downloadElement = document.createElement('a')
  let href = blob
  if (typeof blob == 'string') {
    downloadElement.target = '_blank'
  } else {
    href = window.URL.createObjectURL(blob) //创建下载的链接
  }
  downloadElement.href = href
  downloadElement.download = tagFileName + '.' + fileType //下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() //点击下载
  document.body.removeChild(downloadElement) //下载完成移除元素
  if (typeof blob != 'string') {
    window.URL.revokeObjectURL(href) //释放掉blob对象
  }
}

export default {
  downloadFiles,
}
