<template>
  <div>
    <van-tabs v-model="active" color="#5288f5">
      <van-tab title="基本信息">
        <app-info :appInfo="appInfo" :fileLists="fileLists" :fyrList="fyrList"/>
      </van-tab>
      <van-tab title="交办详情">
        <peruse :peruseList="peruseList"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import Base64 from "@/util/Base64";
  import { Toast } from "vant";
  import appInfo from "@/views/cppcc/components/appInfo/appInfo";
  import peruse from "@/views/cppcc/components/appInfo/peruse";
  import { getAppInfo} from "@/api/appManagement";
  import { getPeruseInfo } from '@/api/peruseManagement'
  import store from "@/store";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: "Info",
    components: { appInfo, peruse },
    data() {
      return {
        active: 0,
        App_ID: 0,
        appInfo: {},
        fileLists:[],
        fyrList:[],
        peruseList: []
      }
    },
    async created() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '加载中...',
        forbidClick: true,
      });
      this.App_ID = Base64.decode(this.$route.query.App_ID)
      await this.fetchAppInfo()
      await this.fetchPeruseInfo()
      Toast.clear()
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      console.log(userInfo)
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/info", "详情", "zzdzx.yy.gov.cn/info")
    },
    methods: {
      async fetchAppInfo() {
        const { data } = await getAppInfo({ID: this.App_ID})
        this.appInfo = data.appInfo
        this.fileLists = data.fileLists
        this.fyrList = data.fyrList
        this.makeNature()
        this.makeFyr()
      },

      async fetchPeruseInfo() {
        const { data } = await getPeruseInfo({App_ID: this.App_ID, Type: 2})
        this.peruseList = data

      },

      //性质组成
      makeNature() {
        //性质字符串组成
        let sum = 0
        let a = this.appInfo.nature.split('|')
        for (let i = 0; i < a.length; i++) {
          sum += Number(a[i])
        }
        switch (sum) {
          case 1:
            this.appInfo.nature = '当年'
            break
          case 2:
            this.appInfo.nature = '多年重复'
            break
          case 3:
            this.appInfo.nature = '当年、多年重复'
            break
          case 4:
            this.appInfo.nature = '重点督办'
            break
          case 5:
            this.appInfo.nature = '当年、重点督办'
            break
          case 6:
            this.appInfo.nature = '多年重复、重点督办'
            break
          case 7:
            this.appInfo.nature = '当年、多年重复、重点督办'
            break
        }
      },

      //附议人
      makeFyr() {
        if (this.fyrList.length > 0) {
          this.appInfo.DGT_List = ''
          this.fyrList.forEach((item) => {
            this.appInfo.DGT_List += item.staff_Name + ','
          })
          this.appInfo.DGT_List = this.appInfo.DGT_List.substring(0,this.appInfo.DGT_List.length - 1)
          this.appInfo.DGT_Num = this.fyrList.length
        }
      }
    }
  }
</script>

<style scoped>
  @import "../../assets/css/jquery-weui.css";
  @import "../../assets/css/demos.css";
  @import "../../assets/css/weui.css";
  @import "../../assets/css/zui.css";

  .middle table {
    background-color: #fff;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 14px;
  }
  .middle table tr th {
    font-weight: normal;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
  }
  .middle table tr th:first-child {
    text-align: left;
  }
  .middle table tr td{
    text-align: center;
  }
  .middle table tr td:first-child {
    text-align: left;
  }
  .weui-cell__bd span{
    color:#5288f5 ;
  }
  article h4,
  h4 {
    top: 0;
    position: sticky;
  }

</style>