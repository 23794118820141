import { request } from '@/util/request'

export function assigned(data) {
  return request({
    url: '/api/Peruse/Assigned',
    method: 'post',
    data,
  })
}

export function getPeruseInfo(params) {
  return request({
    url: '/api/Peruse/GetPeruseInfo',
    method: 'get',
    params,
  })
}

export function singer(params) {
  return request({
    url: '/api/Peruse/Singer',
    method: 'get',
    params,
  })
}

export function reply(data) {
  return request({
    url: '/api/Peruse/Reply',
    method: 'post',
    data,
  })
}

export function rejReply(data) {
  return request({
    url: '/api/Peruse/RejReply',
    method: 'post',
    data,
  })
}

export function replyDet(data) {
  return request({
    url: '/api/Peruse/ReplyDet',
    method: 'post',
    data,
  })
}

export function rejReplyDet(data) {
  return request({
    url: '/api/Peruse/RejReplyDet',
    method: 'post',
    data,
  })
}

export function agreeTB(data) {
  return request({
    url: '/api/Peruse/AgreeTB',
    method: 'post',
    data,
  })
}

export function disAgreeTB(data) {
  return request({
    url: '/api/Peruse/DisAgreeTB',
    method: 'post',
    data,
  })
}

export function getNoSignList(params) {
  return request({
    url: '/api/PeruseDet/GetNoSignList',
    method: 'get',
    params,
  })
}

export function getPeruseListByAppID(params) {
  return request({
    url: '/api/Peruse/GetPeruseListByAppID',
    method: 'get',
    params,
  })
}

export function updatePeruseMaster(data) {
  return request({
    url: '/api/Peruse/UpdatePeruseMaster',
    method: 'post',
    data,
  })
}

export function updatePeruseDet(data) {
  return request({
    url: '/api/Peruse/UpdatePeruseDet',
    method: 'post',
    data,
  })
}
