<template>
  <div class="weui-form-preview" style="font-size: 16px;margin: 14px">
    <div class="weui-form-preview__hd">
      <div class="weui-form-preview__item">
        <p>案件基本信息</p>
      </div>
    </div>
    <div class="weui-form-preview__bd">
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">会议名称</label>
        <span class="weui-form-preview__value">{{appInfo.meet_Name}}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">登记时间</label>
        <span class="weui-form-preview__value">{{appInfo.date_App}}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">状态</label>
        <span class="weui-form-preview__value">{{appInfo.case_Status}}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">案号</label>
        <span class="weui-form-preview__value">{{appInfo.case_ID}}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">案别</label>
        <span class="weui-form-preview__value">提案</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">类别</label>
        <span class="weui-form-preview__value">{{ appInfo.case_Name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">性质</label>
        <span class="weui-form-preview__value">{{ appInfo.nature }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">提案提交单位</label>
        <span class="weui-form-preview__value">{{ appInfo.dlG_Name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">提案者</label>
        <span class="weui-form-preview__value">{{ appInfo.staff_Name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">工作单位</label>
        <span class="weui-form-preview__value">{{ appInfo.dgT_Unit }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">联系人</label>
        <span class="weui-form-preview__value">{{ appInfo.dgT_Name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">联系电话</label>
        <span class="weui-form-preview__value">{{ appInfo.dgT_Tel }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">联系地址</label>
        <span class="weui-form-preview__value">{{ appInfo.address }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">附议人</label>
        <span class="weui-form-preview__value">{{ appInfo.DGT_List }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">附议人数</label>
        <span class="weui-form-preview__value">{{ appInfo.DGT_Num }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">案由</label>
        <span class="weui-form-preview__value">{{ appInfo.case_Action }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">内容</label>
      </div>
      <div style="text-align: left;overflow-wrap: break-word;white-space: pre-wrap;" v-html="appInfo.content"></div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">希望承办的单位</label>
        <span class="weui-form-preview__value">{{ appInfo.hop_ORG }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">审核意见</label>
        <span class="weui-form-preview__value" >{{ appInfo.adT_Type_ID === null ? '' : appInfo.adT_Type_ID === 1 ? '立案' : '不予立案' }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">理由及处理意见</label>
        <span class="weui-form-preview__value">{{ appInfo.adT_Views }}</span>
      </div>
      <br/>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import {Emas} from "@/assets/js/Emas";

  export default {
    name: "appInfo",
    props:{
      appInfo: {type:Object, default: () => {}},
    },
    data() {
      return {

      }
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/appInfo", "案件详情页", "zzdzx.yy.gov.cn/appInfo")
    },

  }
</script>

<style scoped>
  .weui-form-preview__hd{
    text-align: center;
  }
  .weui-cell{ font-size: .9em;}
</style>