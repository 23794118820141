<template>
  <div>
    <div class="weui-form-preview" style="font-size: 16px;margin: 14px"  v-for="(item, i) in peruseList" :key="item.peruse_ID">
      <van-collapse v-model="activeNames">
        <van-collapse-item  :title="peruseList.length > 1 ? '第' + (i + 1) + '次交办': '交办单位' " :name="i">
          <!--主办单位-->
          <el-divider >
            <strong style="font-size: 16px">主办单位</strong>
          </el-divider>
          <div class="weui-form-preview__bd">
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">主办单位</label>
              <span class="weui-form-preview__value">{{item.host.staff_Name}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">交办时间</label>
              <span class="weui-form-preview__value">{{item.host.date_Peruse}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">交办类别</label>
              <span class="weui-form-preview__value">{{item.host.categories_Peruse}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">签发人</label>
              <span class="weui-form-preview__value">{{item.host.singer}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">联系人</label>
              <span class="weui-form-preview__value">{{item.host.linker}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">联系电话</label>
              <span class="weui-form-preview__value">{{item.host.mobile}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">签收时间</label>
              <span class="weui-form-preview__value">{{item.host.date_Get}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">办理类别</label>
              <span class="weui-form-preview__value">{{item.host.process_Type_Name}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">面商次数</label>
              <span class="weui-form-preview__value">{{item.host.iCount}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">答复时间</label>
              <span class="weui-form-preview__value">{{item.host.date_Concluison}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">落实时限</label>
              <span class="weui-form-preview__value">{{item.host.date_Plan}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">已落实事项</label>
            </div>
            <div style="text-align: left;" v-html="item.host.matters_Exec"></div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">计划落实事项</label>
            </div>
            <div style="text-align: left;" v-html="item.host.matters_Plan"></div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">未采纳/落实理由</label>
            </div>
            <div style="text-align: left;" v-html="item.host.matters_Rej_Reason"></div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">答复全文</label>
            </div>
            <div style="text-align: left;" v-html="item.host.conclusion_content"></div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">附件</label>
              <div v-for="itemurl in item.host.fileList" :key="itemurl.url">
                <el-link type="primary" @click="handlePreview(itemurl.url, itemurl.name)" >
                  {{ itemurl.name }}
                </el-link>
              </div>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">退办理由</label>
              <span class="weui-form-preview__value">{{item.host.rej_Reason}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">签发人</label>
              <span class="weui-form-preview__value">{{item.host.rej_Signer}}</span>
            </div>
          </div>
          <!--协办单位-->
          <div class="weui-form-preview__bd" v-show="item.co.length > 0">
            <el-divider v-if="item.co.length > 0">
              <strong style="font-size: 16px">协办单位</strong>
            </el-divider>
            <div v-for="itemCo in item.co" :key="itemCo.id">
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">协办单位</label>
                <span class="weui-form-preview__value">{{itemCo.staff_Name}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">签收时间</label>
                <span class="weui-form-preview__value">{{itemCo.date_Get}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">反馈时间</label>
                <span class="weui-form-preview__value">{{itemCo.date_Concluison}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">签发人</label>
                <span class="weui-form-preview__value">{{itemCo.singer}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">联系人</label>
                <span class="weui-form-preview__value">{{itemCo.linker}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">联系电话</label>
                <span class="weui-form-preview__value">{{itemCo.mobile}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">拟办意见</label>
                <span class="weui-form-preview__value">{{itemCo.conclusion_content}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">附件</label>
                <div v-for="itemurlco in itemCo.fileList" :key="itemurlco.url">
                  <el-link type="primary" @click="handlePreview(itemurlco.url, itemurlco.name)" >
                    {{ itemurlco.name }}
                  </el-link>
                </div>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">退办理由</label>
                <span class="weui-form-preview__value">{{itemCo.rej_Reason}}</span>
              </div>
              <div class="weui-form-preview__item">
                <label class="weui-form-preview__label">签发人</label>
                <span class="weui-form-preview__value">{{itemCo.rej_Signer}}</span>
              </div>
              <br>
            </div>
          </div>
          <!--满意度-->
          <div class="weui-form-preview__bd" v-if="item.feedBack !== null">
            <el-divider >
              <strong style="font-size: 16px">满意度</strong>
            </el-divider>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">面商人</label>
              <span class="weui-form-preview__value">{{item.feedBack.counselor}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">办理协商形式</label>
              <span class="weui-form-preview__value">{{item.feedBack.negotiation_Form}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">采纳情况</label>
              <span class="weui-form-preview__value">{{item.feedBack.adoption}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">对建议答复工作</label>
              <span class="weui-form-preview__value">{{item.feedBack.result_Score1}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">对建议办理措施</label>
              <span class="weui-form-preview__value">{{item.feedBack.result_Score2}}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">对办理答复和落实情况的意见</label>
              <span class="weui-form-preview__value">{{item.feedBack.content}}</span>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
  import download from '@/util/downloadFiles'
  import store from "@/store";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: "peruse",
    props:{
      peruseList: { type:Array, default: () => []},
    },
    data() {
      return {
        activeNames: [0],
        title:''
      }
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/peruse", "交办页面", "zzdzx.yy.gov.cn/peruse")
    },

    methods:{
      handlePreview(url, name) {
        console.log(name)
        download.downloadFiles(
            url,
            name.substring(0, name.length - 20),
            name.split('.')[1]
        )
      },
    }
  }
</script>

<style scoped>
  .weui-form-preview__hd{
    text-align: center;
  }
  .weui-cell{ font-size: .9em;}
</style>